.header {
  display:flex;
  justify-content: space-evenly;
  height:2em;
  margin-top:0.8em;
  align-items: baseline;
}

.header-options:hover {
  font-size:1.7em;
  transition:0.5s;
}

.header-options {
  font-size:1.5em;
  transition:0.5s;
}

.main {
  font-size:2em;
  transition:0.5s;
  width: 20vw;
  margin-left: 1.3em;
}

.main:hover {
  font-size: 2.1em;
  transition:0.5s;
}

.lower-tier {
  display: flex;
  width: 70vw;
  justify-content: space-around;
  align-items: baseline;
}

.selected {
  text-decoration: underline;
  pointer-events: none;
}

.project-description {
  margin-bottom: 1.5em;
  width: 45vw;
}

@media only screen and (max-width: 600px) {

  .main {
    display: none;
  }

}