.bold-text {
  font-weight:800;
  display: inline;
}

.white-space {
  border:none;
  height:1em;
}

.projects-page {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.option-link {
  text-decoration: none;
  color: black;
  font-size: 1.4em;
  margin:0.6em;
  transition:0.3s;
}

.option-link:hover {
  text-decoration: underline;
  transition:0.3s;
}

.single-project {
  display:flex;
  flex-direction: column;
  margin:1em;
  margin-bottom: 5em;
}

.project-image {
  width:40vw;
}

.project-options {
  margin-bottom: 1em;
  height: 1.5em;
}

.project-title {
  margin-bottom: 0.5em;
  align-items: center;
  align-self: center;
}

.page-title {
  margin-top: 0.7em;
  font-size: 65px;
  margin-bottom: 0.7em;
  text-decoration: underline;
}

.project-showcase {
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.showcase-image {
  margin-right:30px;
  border-radius: 30px;
  height: 30em;
  transition: 2s;
}

.showcase-image:hover {
  height:35em;
  transition: height 2s;
}

.project-description {
  align-self: center;
  align-items: center;
}

.pitch {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {

.projects-page {
  margin:0;
}

.project-description {
  margin-bottom: 1.5em;
  width: 90vw;
}

.project-image {
  width:22em;
}

}




