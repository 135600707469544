.fade-in {
  animation: fadeIn 3s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.contact-option {
  color: black;
  display:flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom:1.5em;
}

.contact-text {
  display:flex;
  text-decoration: underline;
  align-items: center;
}

.contact-icons {
  height:2em;
  margin-right: 0.2em
}

.contactinfo {
  text-align: center;
  margin-top: 20vh;
}

@media only screen and (max-width: 600px) {

  .page-title {
    font-size:50px;
    margin-bottom: 0.3em;
  }

  .contact-option {
    font-size:15px;
  }

  .contactinfo {
    margin:0;
  }

  .contact-text {
    margin-bottom:1em;
  }


}